@import "utils/setup";
@import "sections/header";
@import "sections/videos";
@import "sections/footer";
@import "sections/other-categories";
@import "components/grid-item";
@import "components/sidebar-items.scss";
@import "components/text-item";
@import "components/badge";
@import "components/video-item";
@import "components/burger-menu";
@import "components/search";
@import "components/citation-item";
@import "components/dialog";
@import "components/cookies-dialog";

html {
  line-height: 1.5;
  font-size: 100%;
  color: $text-color;
  height: 100%;

  @include lg() {
    font-size: 16px;
  }
}

body {
  font-family: $font-primary;
  background-color: $white;
  color: $text-color;
}

*,
:after,
:before {
  box-sizing: border-box;
}

button,
input {
  font-family: $font-primary;
  appearance: none;

  &:focus {
    outline: none;
  }
}

button {
  padding: 0;
  border: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.container {
  // 1312px + 60px
  max-width: calc(1312px + 60px);
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 0 auto;

  @include sm() {
    padding: 0 16px;
  }
}

.filter-grayscale {
  filter: grayscale(1);
}

.swiper-button-prev,
.swiper-button-next {
  // left: auto;
  // top: auto;
  // right: auto;
  position: relative;
  min-width: 56px;
  min-height: 16px;

  &::after {
    display: none;
  }
}

.arrow-navigation {
  position: absolute;
  top: 0;
  right: 0;
  width: 128px;
  display: flex;
}

.swiper-button-prev {
  margin-left: -21px;
}

.d-flex {
  display: flex;
}

a {
  text-decoration: none;
}

.justify-content-b {
  justify-content: space-between;
}

.justify-content-c {
  justify-content: center;
}

.align-items-s {
  align-items: start;
}

.align-items-e {
  align-items: end;
}

.align-items-c {
  align-items: center;
}

.align-self-s {
  align-self: flex-start;
}

.flex-column {
  flex-direction: column;
}

.hidden-xs {
  @include xs {
    @include hidden();
  }
}

.hidden-sm {
  display: block;

  @include sm() {
    display: none !important;
  }
}

.hidden-md {
  display: block;

  @include md() {
    display: none !important;
  }
}

.hidden-md-flex {
  display: flex;

  @include md() {
    display: none !important;
  }
}

.hidden-lg {
  display: none;

  @include md() {
    display: flex !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rotate-180 {
  transition: transform 0.2s ease-in-out;
  transform: rotate(180deg) !important;
}

.mt-16 {
  margin-top: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.grid-section {
  margin-bottom: 96px;

  @include sm() {
    margin-bottom: 56px;
  }

  .load-more-btn {
    margin-top: 43px;

    @include sm() {
      margin-top: 34px;
    }
  }
}

.grid-blocks {
  display: grid;
  gap: 34px;
  padding-top: 32px;
  margin-bottom: 43px;

  @include sm() {
    gap: 26px;
    padding-top: 24px;
    margin-bottom: 34px;
  }

  &_type-1 {
    grid-template-areas: "a b c" "d d e";
    grid-template-columns: 1fr 1fr 1fr;

    @include md() {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "a b" "c e" "d d";
    }

    @include sm() {
      grid-template-columns: 1fr;
      grid-template-areas: "a" "b" "c" "e" "d";
    }

    .grid-item {
      @include sm() {
        min-height: 382px;
      }
    }

    >div {

      &:nth-child(1) {
        grid-area: a;
      }

      &:nth-child(2) {
        grid-area: b;
      }

      &:nth-child(3) {
        grid-area: c;
      }

      &:nth-child(4) {
        grid-area: d;
      }

      &:nth-child(5) {
        grid-area: e;
      }
    }
  }

  &_type-2 {
    grid-template-columns: 8.33fr 4fr;

    @include md() {
      grid-template-columns: 1fr;
    }

    &-inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 34px;

      @include sm() {
        grid-template-columns: 1fr;
        gap: 26px;
      }
    }
  }

  &_type-3 {
    grid-template-columns: 1fr 1fr 1fr;

    @include md() {
      grid-template-columns: 1fr 1fr;
    }

    @include sm() {
      grid-template-columns: 1fr;
    }
  }

  &_type-4 {
    grid-template-areas: "a b c";
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 0;

    @include md() {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "a b" "c e";
    }

    @include sm() {
      grid-template-columns: 1fr;
      grid-template-areas: "a" "b" "c";
    }

    .grid-item {
      @include sm() {
        min-height: 382px;
      }
    }

    >div {

      &:nth-child(1) {
        grid-area: a;
      }

      &:nth-child(2) {
        grid-area: b;
      }

      &:nth-child(3) {
        grid-area: c;
      }

      &:nth-child(4) {
        grid-area: d;
      }

      &:nth-child(5) {
        grid-area: e;
      }
    }
  }

  &__text-item-news {
    .text-item {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.btn-circle {
  @include size(32px, 32px);
  border: solid 1.5px $neutral-100;
  cursor: pointer;
  border-radius: 100%;
  @include center-flex();
  background-color: transparent;
}

.polling {
  &__polls {
    display: flex;
    flex-direction: column;
  }

  &__votes {
    display: block;
    margin-top: 55px;
    @include font(14, 22px, 400);
  }

  &__item {
    display: flex;
    cursor: pointer;
    margin-bottom: 23px;
    width: 201px;
    height: 100%;
    flex-direction: column;

    &:hover {
      .polling__item-checkbox {
        background-color: $white;
      }
    }

    &:active {
      .polling__item-checkbox {
        border-width: 7px;
      }
    }

    &:last-child {
      margin-bottom: 0
    }

    >div {
      &:first-child {
        display: flex;
        align-items: center;
      }
    }

    &-checkbox {
      //@include size(24px, 24px);
      min-width: 24px;
      min-height: 24px;
      border-radius: 100%;
      border: solid 5px $primary;
      display: block;
      margin-right: 16px;
    }

    &-text {
      @include font(14, 22px, 700);
      position: relative;
      top: 2px;
    }

    &-percent {
      display: none;
      margin-right: 16px;
      @include font(14, 22px, 700);
      position: relative;
      top: 2px;
      color: #E4D6FF;
      width: 50px;
      white-space: nowrap;
    }

    &-progress {
      height: 8px;
      background-color: #E4D6FF;
      border-radius: 4px;
      width: 100%;
      margin-top: 6px;
      display: none;
      margin-bottom: -15px;
    }

    &_active {
      .polling__item-progress {
        background-color: $primary;
      }

      .polling__item-percent {
        color: $primary;
      }
    }
  }
}

.load-more-btn {
  @include font(16, 24px, 700);
  @include center-flex();
  color: $neutral-100;
  text-decoration: none;

  span {
    position: relative;
    left: 0;
    transition: left 0.2s ease-in-out;
  }

  &:hover {
    span {
      left: -5px;
    }
  }

  svg,
  img {
    margin-left: 16px;
  }
}

.hidden {
  display: none !important;
}

div.banner-hidden {
  transform: translateY(-350px);
  max-height: 0px;
}

.o-hidden {
  overflow: hidden;
}

.btn-share {
  @include font(16, 24px, 700);
  padding: 4px 12px;
  padding-top: 6px;
  border: solid 1px $neutral-100;
  color: $neutral-100;
  border-radius: 40px;
  cursor: pointer;
  @include center-flex();
  max-height: 28px;
  background-color: transparent;

  span {
    position: relative;
    top: 1.25px;
  }

  svg,
  img {
    margin-left: 8px;
    min-width: 16.4px;
    min-height: 16.4px;
  }
}

.btn-like {
  position: relative;
  padding: 13px 0;

  &__btn {
    cursor: pointer;
    @include font(16, 19.8px, 700);
    border: solid 1px $neutral-100;
    position: relative;
    color: $neutral-100;
    border-radius: 48px;
    max-height: 34px;
    width: 100%;
    height: 100%;

    >div {
      padding: 7px 15px 7px 11px;
      display: flex;
      align-items: center;

      >img {
        margin-right: 8px;
        display: inline-block;
        @include size(20px, 20px);
        position: relative;
        top: -1px;
      }
    }
  }

  &_with-emojis {
    background-color: $neutral-40;
    border-color: $neutral-40;

    &:hover {
      background-color: $neutral-50;
    }
  }

  &_activated {
    .btn-like__btn {
      border-color: $primary-purple;
      background-color: $primary-purple;

      >div {
        >span {
          position: relative;
          top: 1px;
        }
      }
    }

    .btn-like__inner {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &:hover {
    .btn-like__article-emoji {
      display: flex;
    }
  }

  &__article-emoji {
    position: absolute;
    height: 34px;
    display: none;
    align-items: center;
    background-color: $white;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 48px;
    left: 0;
    top: calc(-13px - 34px);
    padding: 0 6px;
    width: fit-content;

    &::before {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 10px;
      width: 0;
      height: 0;
      z-index: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 22px solid $white;
    }

    li {
      font-size: 20px;
      display: block;
      height: 32px;
      padding: 0 6px;
      border-radius: 50%;
      @include center-flex();
      position: relative;
      z-index: 1;

      span {
        display: none;
        white-space: nowrap;
        position: absolute;
        top: -60px;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        color: $white;
        padding: 16px;
        background-color: #222222;
        box-shadow: 0px 8px 16px rgba(14, 30, 46, 0.38);
        border-radius: 16px;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          position: absolute;
          bottom: -12px;
          margin: 0 auto;
          width: 0;
          height: 0;
          z-index: 0;
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-top: 22px solid #222222;
        }
      }

      &:hover {
        span {
          display: flex;
        }
      }

      >img {
        @include size(20px, 20px);
        display: block;
      }

      &:active {
        background-color: $primary-purple;
      }
    }
  }

  &_emoji-style {
    .btn-like__btn {
      background-color: $neutral-40;
      border-color: transparent;

      &:hover {
        background-color: $neutral-50;
      }
    }
  }
}

.image-flow {
  float: left;
  margin-right: 32px;

  @include xs() {
    margin-right: 0;
  }

  @include sm() {
    padding-bottom: 16px;
  }
}

.mb-empty {
  margin-bottom: 0;

  &p {
    margin-bottom: 0;
  }
}

.large-img {
  img {
    min-height: 377px;

    @include sm() {
      min-height: 165px;
    }
  }
}

.v-hidden {
  visibility: hidden;
}

.home-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_mob {
    display: none;

    @include sm {
      display: block;
    }
  }

  &_desktop {
    @include sm {
      display: none;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 1315px;
  }
}